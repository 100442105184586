var host = "https://api-a19.smicee.com";


const { h } = require("vue");

module.exports = {
    V1API: {
        'getAccessToken': host + '/api/auth/token',
        'refreshAccessToken': host + '/api/auth/token/refresh/',
        'verifyLogin': host + '/api/auth/token/verify',
        //article 19

        //Campaign
        //Campaign Category
        'create_campaign_category':host+'/api/campaign/category/create',
        'delete_campaign_category':host+'/api/campaign/category/delete/',
        'update_campaign_category':host+'/api/case/category/update/',
        'campaign_category_list':host+'/api/campaign/category/',

        'create_campaign':host+'/api/campaign/create',
        'update_campaign':host+'/api/campaign/update/',
        'campaign_list':host+'/api/campaign/',
        'campaign_delete':host+'/api/campaign/delete/',
        'get_campaign_details':host+'/api/campaign/detail/',
        'campaign_custom_toggle':host+'/api/campaign/custom-ui/toggle/',


        //Law
        //Law Category
        'create_law_category':host+'/api/law-n-policy/category/create',
        'delete_law_category':host+'/api/law-n-policy/category/delete/',
        'update_law_category':host+'/api/law-n-policy/category/update/2',
        'law_category_list':host+'/api/law-n-policy/category/',

        'create_law':host+'/api/law-n-policy/create',
        'update_law':host+'/api/law-n-policy/update/',
        'law_list':host+'/api/law-n-policy/',
        'law_delete':host+'/api/law-n-policy/delete/',
        'get_law_details':host+'/api/law-n-policy/detail/',
        'law_custom_toggle':host+'/api/law-n-policy/custom-ui/toggle/',


        //Press Release
        'get_press_list':host+'/api/press-release/',
        'create_press':host+'/api/press-release/create',
        'update_press':host+'/api/press-release/update/',
        'delete_press':host+'/api/press-release/delete/',
        'press_detail':host+'/api/press-release/detail/',

        //Quotes
        'get_quotes_list':host+'/api/press-release/quotes',
        'create_quotes':host+'/api/press-release/quotes/create',
        'update_quotes':host+'/api/press-release/quotes/update/',
        'delete_quotes':host+'/api/press-release/quotes/delete/',
        'quotes_detail':host+'/api/press-release/quotes/detail/',
       
   


        //News
        //News Category
        'create_news_category':host+'/api/news/category/create',
        'delete_news_category':host+'/api/news/category/delete/',
        'update_news_category':host+'/api/news/category/update/',
        'news_category_list':host+'/api/news/category/',
        

        'create_news':host+'/api/news/create',
        'update_news':host+'/api/news/update/',
        'news_list':host+'/api/news/',
        'news_delete':host+'api/news/delete/',
        'get_news_details':host+'/api/news/detail/',
        'news_custom_toggle':host+'/api/news/custom-ui/toggle/',

        //Gallery
        'create_gallery':host+'/api/gallery/create',
        'update_gallery':host+'/api/gallery/update/',
        'gallery_list':host+'/api/gallery/',
        'gallery_delete':host+'/api/gallery/delete/',
        'get_gallery_details':host+'/api/news/detail/',



        //Content
        'create_content':host+'/api/content/create',
        'update_content':host+'/api/content/update/',
        'content_list':host+'/api/content/',
        'content_delete':host+'/api/content/delete/',
        'get_content_details':host+'/api/content/detail/',
        'get_content_by_type_slug':host+'/api/content/by-type-slug/',
        'get_content_by_type':host+'/api/content/by-type/',


        //Counter
        'add_counter':host+'/api/home/count/create',
        'update_counter':host+'/api/home/count/update/',
        'counter_list':host+'/api/home/count',
        'counter_delete':host+'/api/home/count/delete/',


        //QNA
        'create_qna':host+'/api/home/qna/create',
        'update_qna':host+'/api/home/qna/update/',
        'qna_list':host+'/api/home/qna',
        'qna_delete':host+'/api/home/qna/delete/',
        'get_qna_details':host+'/api/home/qna/detail/',
        'toogle_qna_visiblity':host+'/api/home/qna/open/toggle/',



        //Contact
        'all_message':host+'/api/contact/message/list/',
        'message_delete':host+'/api/contact/delete/',
        

        //Team Member
        'create_team':host+'/api/team/create',
        'update_team':host+'/api/team/update/',
        'team_list':host+'/api/team/',
        'team_delete':host+'/api/team/delete/',
        'get_team_details':host+'/api/team/detail/',



        //Slider        
        'create_slider':host+'/api/home/slider/create',
        'update_slider':host+'/api/home/slider/update/',
        'slider_list':host+'/api/home/slider',
        'slider_delete':host+'/api/home/slider/delete/',
        'get_slider_details':host+'/api/home/slider/detail/',


        //Partner        
        'create_partner':host+'/api/home/partners/create',
        'update_partner':host+'/api/home/partners/update/',
        'partner_list':host+'/api/home/partners',
        'partner_delete':host+'/api/home/partners/delete/',
        'get_partner_details':host+'/api/home/partners/detail/',


        //Annual Report        
        'create_annual_report':host+'/api/annual-report/create',
        'update_annual_report':host+'/api/annual-report/update/',
        'annual_report_list':host+'/api/annual-report/',
        'annual_report_delete':host+'/api/annual-report/delete/',
        'get_annual_report_details':host+'/api/annual-report/details/',
  
        
        //Base Detail
        'create_base_detail':host+'/api/base-detail/create/',
        'get_base_detail':host+'/api/base-detail/by-type/',
        

        //Site Settings
        'create_site_setting':host+'/api/home/settings/create',
        'get_site_setting':host+'/api/home/settings/',
        
        //Subscribers
        'get_subscribers_list':host+'/api/home/subscription',

        //Application Form
        'create_application': host+ '/api/application/create',
        'get_application': host+ '/api/application/list',
        'delete_application': host+ '/api/application/delete/',
        'edit_application': host+ '/api/application/edit/',
        'application_details': host+ '/api/application/details/',
        'create_application_form': host+ '/api/application/form/create',
        'get_application_form': host+ '/api/application/form/by-application/',
        'get_application_form_detail':host+'/api/application/form/detail/',
        'submit_application_form': host+ '/api/application/form/submission/create',
        'get_application_form_responses': host+ '/api/application/form/submission/by-form/',
        'delete_application_form_responses':host+'/api/application/form/submission/delete/',
        'get_application_form_list':host+'/api/application/form',
      
        
        //What we do
        'create_what_we_do':host+'/api/we-do/create/',
        'get_what_we_do':host+'/api/we-do/',
        'what_we_do':host+'/api/we-do/delete/',
        'get_what_we_do_detail':host+'/api/we-do/by-type/',
        'what_we_do_custom_toggle':host+'/api/we-do/custom-ui/toggle/',

        //Jobs

        'job_list':host+'/api/job/admin-view/position/list',
        'job_create':host+'/api/job/position/create',
        'job_edit':host+'/api/job/position/edit/',
        'job_details':host+'/api/job/position/',
        'job_delete':host+'/api/job/position/delete/',
        'job_status_toggle':host+'/api/job/position/toggle/',


        //Fellowship
        'fellowship_list':host+'/api/fellowship/admin-view/list',
        'fellowship_create':host+'/api/fellowship/create',
        'fellowship_edit':host+'/api/fellowship/edit/',
        'fellowship_details':host+'/api/fellowship/details/',
        'fellowship_delete':host+'/api/fellowship/delete/',
        'fellowship_status_toggle':host+'/api/fellowship/toggle/',

        //Call for Proposal
        'cfp_list':host+'/api/cfp/admin-view/list',
        'cfp_create':host+'/api/cfp/create',
        'cfp_edit':host+'/api/cfp/edit/',
        'cfp_details':host+'/api/cfp/details/',
        'cfp_delete':host+'/api/cfp/delete/',
        'cfp_status_toggle':host+'/api/cfp/toggle/',


        //Audio Visuals
        'av_list':host+'/api/audio-visual/',
        'av_create':host+'/api/audio-visual/create',
        'av_edit':host+'/api/audio-visual/update/',
        'av_details':host+'/api/audio-visual/detail/',
        'av_delete':host+'/api/audio-visual/delete/',



        //Infography
        'infography_list':host+'/api/infography/',
        'infography_create':host+'/api/infography/create',
        'infography_edit':host+'/api/infography/edit/',
        'infography_details':host+'/api/infography/details/',
        'infography_delete':host+'/api/infography/delete/',

        //Where We Work
        'where_we_work_detail':host+'/api/where-we-work/',
        'where_we_work_create' : host+'/api/where-we-work/update'


        // article19 end
        
        
    }
};
